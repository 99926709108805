import React from "react";
import { Link } from "gatsby";

const PageBanner = () => (
  <section className="aq-feature-bg bg-light-blue pb-9 pb-lg-16 light-blue-overlay">
    <div className="container-fluid">
      <div className="component-wrap">
        <div className="row gx-0">
          <div className="col-lg-5 mt-8 left">
            <h1 className="h1-three-line scaled mt-5 mb-14 d-none d-lg-block">
              <span>Aquanauts,</span>
              <br />
              <span>are you</span>
              <br />
              <span>ready?</span>
            </h1>
            <div className="col-lg-7 pb-3 image-mobile d-lg-none">
              <img
                src="/images/Characters/Group-Pose-Mobile-Christmas.png"
                className="w-100"
                alt="The Aquanauts in a group pose"
              />
            </div>
            <div className="pt-3 pl-0 pl-lg-8">
              <h3 style={{ color: "#136067" }}>
                Be a hero! Join the Aquanauts on their fun filled adventures to
                save water and look after wildlife, with lots of exciting
                missions to complete.
              </h3>
              <Link className="btn btn-teal mt-5" to="/activities">
                View activities
              </Link>
            </div>
          </div>
          <div className="col-lg-7 image right d-none d-lg-block">
            <img
              src="/images/Characters/Group-Pose-Christmas.svg"
              className="w-100"
              alt="The Aquanauts in a group pose"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default PageBanner;
